import React, { useState } from 'react';
import './App.css';

function App() {
    const [showMenu, setShowMenu] = useState(false);

    function toggleMenu() {
        setShowMenu(!showMenu);
    }

    return (
        <div className="App">
            <header>
                <h1>Isaiah Benodin</h1>
                <nav className={showMenu ? 'show-menu' : ''}>
                    <ul>
                        <li><a href="#about">About</a></li>
                        <li><a href="#experience">Experience</a></li>
                        <li><a href="#education">Education</a></li>
                        <li><a href="#skills">Skills</a></li>
                        <li><a href="#contact">Contact</a></li>
                    </ul>
                </nav>
                <button className="menu-toggle" onClick={toggleMenu}>Menu</button>
            </header>

            <main>
                <section id="about">
                    <img src="./me.jpg" alt="Profile picture" />
                    <div>
                        <h2>Welcome to my website!</h2>
                        <p>Hi and welcome to my site built in React. I'm a software engineer with over 10 years of experience in various programming languages
                            and frameworks. I obsess over problem solving and have a strong work ethic and a passion for learning new technologies and techniques. I have a proven ability to be creative and design solutions effectively in a team environment and am adaptable to any project or role.</p>
                    </div>
                </section>

                <section id="experience">
                    <h2>Experience</h2>
                    <ul>
                        <li>
                            <h3>Escape Room Developer / Software Engineer</h3>
                            <p>That Escape Place</p>
                            <p>2016 - Current</p>
                            <ul>
                                <li>Designed multiple themed immersive experiences from scratch.</li>
                                <li>Wrote clean, clear and well-tested code for various projects.</li>
                                <li>Offered friendly and efficient service to customers, handling challenging situations with ease.</li>
                                <li>Trained new employees in specific job requirements.</li>
                                <li>Designed and wired electronic props and puzzles.</li>
                                <li>Reviewed project specifications and designed technology solutions that met or exceeded performance expectations.</li>
                            </ul>
                        </li>
                        <li>
                            <h3>Software Developer for Web3 Projects</h3>
                            <p>Rage Entertainment LLC</p>
                            <p>2020 - 2022</p>
                            <ul>
                                <li>Developed and deployed decentralized applications on the Ethereum blockchain.</li>
                                <li>Created smart contracts in Solidity for various use cases.</li>
                                <li>Integrated with various third-party tools and protocols such as Metamask, IPFS, and OpenZeppelin.</li>
                                <li>Collaborated with a team of developers to ensure project success and completion.</li>
                                <li>Utilized JavaScript and React for front-end development.</li>
                            </ul>
                        </li>
                        <li>
                            <h3>Contracted Freelancer</h3>
                            <p>2017 - Current</p>
                            <ul>
                                <li>Developed and published several mobile games for iOS and Android platforms.</li>
                                <li>Implemented various game mechanics such as physics, AI, and networking.</li>
                                <li>Utilized C# for game development and scripting.</li>
                                <li>Collaborated with artists and designers to ensure a cohesive and engaging user experience.</li>
                                <li>Developed and maintained various web applications for clients using technologies such as HTML, CSS, JavaScript, and Python.</li>
                                <li>Designed and implemented custom APIs for various clients, leveraging a deep understanding of backend development and API design principles.</li>
                                <li>Provided technical guidance and mentoring to non-technical clients.</li>
                                <li>Managed project timelines and deliverables to ensure client satisfaction and project success.</li>
                            </ul>
                        </li>
                        <li>
                            <h3>Emergency Medical Technician</h3>
                            <p>City Ambulance Service</p>
                            <p>2022 - Current</p>
                            <ul>
                                <li>Maintained levelheadedness and efficiency in high-pressure situations, effectively prioritizing tasks to save lives and provide medical care.</li>
                                <li>Communicated with patients to gather incident and medical history information.</li>
                            </ul>
                        </li>
                        <li>
                            <h3>Church Media Volunteer</h3>
                            <p>Church Project</p>
                            <p>2019 - Current</p>
                            <ul>
                                <li>Assisted in the production of church services.</li>
                                <li>Worked with and maintained audio / video equipment.</li>
                                <li>Edited and uploaded videos for on-demand viewing.</li>
                            </ul>
                        </li>
                    </ul>
                    </section>
                <section id="education">
                    <h2>Education</h2>
                    <ul>
                        <li className="education-item">
                            <div className="education-date">
                                <span>2023</span>
                            </div>
                            <div className="education-info">
                                <h3>Bachelor of Science: Information Systems - Senior </h3>
                                <p>Liberty University - Lynchburg, VA</p>
                                <p>Graduating in summer 2023</p>
                            </div>
                        </li>
                        <li className="education-item">
                            <div className="education-date">
                                <span>2022</span>
                            </div>
                            <div className="education-info">
                                <h3>Emergency Medical Technician License</h3>
                                <p>Lonestar College - Conroe, TX</p>
                            </div>
                        </li>
                        <li className="education-item">
                            <div className="education-date">
                                <span>2016</span>
                            </div>
                            <div className="education-info">
                                <h3>High School Diploma</h3>
                                <p>Homeschool - Montgomery, TX</p>
                            </div>
                        </li>
                    </ul>
                </section>

                <section id="skills">
                    <h2>Skills & Abilities</h2>
                    <ul className="skills-list">
                        <li>C#</li>
                        <li>C++</li>
                        <li>Python</li>
                        <li>JavaScript</li>
                        <li>React</li>
                        <li>Solidity</li>
                        <li>Custom API Solutions</li>
                        <li>Server Management</li>
                        <li>Problem Solving</li>
                        <li>Critical Thinking</li>
                        <li>Excellent Communication</li>
                        <li>Teamwork</li>
                        <li>Creative</li>
                    </ul>
                </section>

                <section id="contact">
                    <h2>Contact Me</h2>
                    <form className="contact-form" action="https://formspree.io/f/mrgvyjqw" method="POST">
                        <label htmlFor="name">Name</label>
                        <input type="text" id="name" name="name" required />

                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" name="email" required />

                        <label htmlFor="message">Message</label>
                        <textarea id="message" name="message" required></textarea>

                        <button type="submit">Send Message</button>
                    </form>
                </section>
            </main>

            <footer>
                <div className="footer-container">
                    <div className="social-media">
                        <a href="#"><i className="fa fa-twitter"></i></a>
                        <a href="#"><i className="fa fa-linkedin"></i></a>
                        <a href="#"><i className="fa fa-github"></i></a>
                    </div>
                    <p>&copy; 2023 Isaiah Benodin</p>
                </div>
            </footer>
        </div>
    );
}

export default App;

